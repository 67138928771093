import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import en from './lang/en.json'
import cn from './lang/cn.json'
import Axios from 'axios'

// baseURL 设置为测试环境地址
Axios.defaults.baseURL = 'http://'
Axios.defaults.baseURL += document.location.hostname
Axios.defaults.baseURL += ':18866'
Vue.prototype.$axios = Axios
Vue.config.productionTip = false

Vue.config.productionTip = false

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'cn',
  messages: {
    en,
    cn,
  },
})

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
